.western {
    font-variant-east-asian: normal;
    font-family: "Noto Sans", sans-serif;
}

.chinese-mainland {
    font-variant-east-asian: simplified; /* <east-asian-variant-values> */
    font-family: "Noto Sans CJK SC", sans-serif;
}

.chinese-taiwan {
    font-variant-east-asian: traditional; /* <east-asian-variant-values> */
    font-family: "Noto Sans CJK TC", sans-serif;
}

.japanese {
    font-variant-east-asian: jis04; /* <east-asian-variant-values> */
    font-family: "Noto Sans CJK JP", sans-serif;
}