$dark: #212121;

:root {
  --p-grid-cols: 1;
  --p-grid-rows: 1;
}

.box_p {
  background-color: $dark;
  padding: 10px;
}

.grid {
  display: grid;
  grid-gap: 0 0;
  background-color: $dark;
  grid-template-rows: repeat(var(--p-grid-rows), 1fr);
  grid-template-columns: repeat(var(--p-grid-cols), 1fr);
}

.node {
  padding-top: 100%;
  background-color: #EDF2F7;
}

.plus {
  background-color: $dark;
}

.plus-center {
  background-color: #1dd1a1;
}

.node-begin {
  background-color: #FC8181;
  transition: ease-in-out 200ms;
}

.node-end {
  background-color: #63B3ED;
  transition: ease-in-out 200ms;
}

.node-wall {
  background-color: #718096;
  transition: all 250ms;
}

.node-shortest-path {
  background-color: #319795;
  transition: ease-in-out 25ms;
}

.node-visited {
  background-color: #CBD5E0;
  transition: ease-in-out 100ms;
}