.rounded-b {
  border-radius: 5px;
}

.shadowT {
  box-shadow: 0 0.5px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.nmt-5 {
  margin-top: 2px;
}